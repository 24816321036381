import React, { Component } from 'react';
import styled from 'styled-components/macro';

import Downshift from 'downshift';
import { colors, defaultRadius, distances, palette, shadows, zIndex } from '../../styles/constants';
import Input from './Input';
import { InputValidation } from './validation';

export type OnChangeFunction = (event: Event) => void;

interface SelectProps {
    name: string;
    value: any;
    label?: any;
    onChange: (option: any) => void;
    disabled?: boolean;
    options: any[];
    hideOnNoOptions?: boolean;
    isEditable?: boolean;
    validation?: InputValidation;
    maxOptions?: number;
    maxOptionsReachedMessage?: string;
    noOptionsMessage?: string;
    autofocus?: boolean;
    getOptionLabel: (option: any) => string;
    getOptionSubLabel?: (option: any) => string;
    getOptionValue: (option: any) => string;
    onInputChange: (option: any) => void;
    placeholder: string;
    autoFocus?: boolean;
    onBlur: (event: React.FormEvent<HTMLInputElement>) => void;
    required?: boolean;
    lastOption?: React.ReactNode;
    belowInput?: React.ReactNode;
    leftIcon?: React.ReactNode;
}

/*
Select, Autocomplete, Typeahead
 */
class Select extends Component<SelectProps> {

    static defaultProps = {
        autoFocus: false,
        maxOptions: 100,
    };

    render() {
        const {
            name,
            value,
            label,
            options,
            onChange,
            disabled,
            getOptionLabel,
            getOptionSubLabel,
            getOptionValue,
            hideOnNoOptions,
            onInputChange,
            placeholder,
            validation,
            maxOptions = 100,
            maxOptionsReachedMessage,
            noOptionsMessage,
            autoFocus,
            onBlur,
            isEditable,
            required,
            lastOption,
            belowInput,
            leftIcon,
        } = this.props;

        const maxOptionsReached = options.length > maxOptions;
        const showOptions = hideOnNoOptions ? options.length > 0 : true;
        return (
            <Wrapper>
                <Downshift
                    onChange={selection => {
                        onChange(selection);
                    }}
                    selectedItem={isEditable ? value : undefined}
                    itemToString={item => (item ? getOptionLabel(item) : '')}
                >
                    {({
                        getInputProps,
                        getItemProps,
                        getMenuProps,
                        isOpen,
                        inputValue,
                    }) => {
                        return (
                            <div>
                                <Input {...getInputProps({
                                    placeholder,
                                    onChange: onInputChange,
                                    label,
                                    name,
                                    onBlur,
                                    leftIcon,
                                })}
                                required={required}
                                value={value}
                                autoFocus={autoFocus}
                                disabled={disabled}
                                validation={validation}
                                autocomplete="off"
                                mb={belowInput ? '0' : undefined}
                                />
                                {showOptions && <Options {...getMenuProps()} open={isOpen}>
                                    {isOpen
                                        ? options
                                            .filter((item, index) => {
                                                return !maxOptions || index < maxOptions;
                                            })
                                            .map((item, index) => {
                                                return (
                                                    <Option
                                                        {...getItemProps({
                                                            key: getOptionValue(item),
                                                            index,
                                                            item,
                                                        })}
                                                    >
                                                        <OptionLabel title={getOptionLabel(item)}>
                                                            {getOptionLabel(item)}
                                                        </OptionLabel>
                                                        {getOptionSubLabel &&
                                                        <OptionSublabel className="sub-label">{getOptionSubLabel(item)}</OptionSublabel>}
                                                    </Option>
                                                );
                                            })
                                        : null}
                                    {maxOptionsReached &&
                                    maxOptionsReachedMessage &&
                                    <MoreSpecific>{maxOptionsReachedMessage}</MoreSpecific>
                                    }
                                    {options.length === 0 &&
                                    inputValue &&
                                    inputValue.length > 2 &&
                                    <MoreSpecific>{noOptionsMessage}</MoreSpecific>
                                    }
                                    {lastOption}
                                </Options>}
                                {belowInput}
                            </div>
                        );
                    }}
                </Downshift>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    position: relative;
    width: 100%;
`;

interface OptionsProps {
    open: boolean;
}

const Options = styled.ul<OptionsProps>`
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 72px;
    z-index: ${zIndex.top};
    background-color: ${colors.background};
    box-shadow: ${shadows.small};
    border-radius: ${defaultRadius};
    transition: all 150ms cubic-bezier(0.2, 0, 0.2, 1);
    transform-origin: top center;
    overflow: hidden;
    ${props => (props.open ? `
        transform: translateY(0);
        opacity: 1;
        pointer-events: all;
    ` : `
        transform: translateY(-10%);
        opacity: 0;
        pointer-events: none;
    `)}
`;

interface OptionProps {
    isPlaceholder: boolean;
}

interface OptionProps {
    selected: boolean;
}

const Option = styled.li<OptionProps>`
    margin: ${distances.micro};
    padding: ${distances.small12} ${distances.small12};
    white-space: nowrap;
    cursor: pointer;
    border-radius: ${defaultRadius};

    ${props => (props['aria-selected'] ? `
        background-color: ${palette.primary[500]};
        color: white;
        .sub-label {
            color: white;
        }
    `: `
        background-color: inherit;
    `)};

    &:hover {
        background-color: ${palette.primary[500]};
        color: white;
        .sub-label {
            color: white;
        }
    }
`;

const MoreSpecific = styled.li`
    margin: 0;
    padding: ${distances.tiny} 16px;
    white-space: nowrap;
    font-size: 12px;
    border-top: 1px solid ${colors.borderLight};
`;

const OptionLabel = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const OptionSublabel = styled.div`
    font-size: 12px;
    color: ${colors.textSecondary};
`;

export default Select;
