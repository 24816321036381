import { keyframes } from 'styled-components/macro';

// New palette added by Rob, should eventually replace most other colors
const palette = {
    primary: {
        50: '#F2F6FF',
        100: '#D9E4FF',
        200: '#BFD1FF',
        300: '#A6BAFF',
        400: '#6889FE',
        500: '#0000FF',
        600: '#0000DF',
        700: '#0000BF',
        800: '#000080',
        900: '#000040',
    },
    warning: {
        50: '#FFFBEB',
        100: '#FEF3C7',
        200: '#FDE68A',
        300: '#FCD34D',
        400: '#FBBF24',
        500: '#F59E0B',
        600: '#D97706',
        700: '#B45309',
        800: '#92400E',
        900: '#78350F',
    },
    success: {
        50: '#F0FDF4',
        100: '#DCFCE7',
        200: '#BBF7D0',
        300: '#86EFAC',
        400: '#4ADE80',
        500: '#22C55E',
        600: '#16A34A',
        700: '#15803D',
        800: '#166534',
        900: '#14532D',
    },
    destructive: {
        50: '#FEF2F2',
        100: '#FEE2E2',
        200: '#FECACA',
        300: '#FCA5A5',
        400: '#F87171',
        500: '#EF4444',
        600: '#DC2626',
        700: '#B91C1C',
        800: '#7F1D1D',
        900: '#7F1D1D',
    },
    neutral: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#E5E5E5',
        300: '#D4D4D4',
        400: '#A3A3A3',
        500: '#737373',
        600: '#525252',
        700: '#404040',
        800: '#262626',
        900: '#171717',
    },
    darkmode: {
        50: '#343448',
        100: '#313146',
        200: '#2F2F43',
        300: '#2A2A3F',
        400: '#28283D',
        500: '#232339',
        600: '#202036',
        700: '#1E1E34',
        800: '#191930',
        900: '#0D0D25',
    },
    white: '#fff',
};

const colors = {
    background: '#fff', // white
    backgroundAlt: palette.neutral[50], // light grey
    backgroundInset: 'rgba(240, 249, 254, 0.27)', // transparent light grey

    borderLight: palette.neutral[100], // light grey
    borderDark: palette.neutral[200], // medium gray
    borderEmphasized: palette.neutral[300], // strong gray

    primary: palette.primary[500], // blue
    primaryHover: palette.primary[400], // blue lighter
    primaryDisabled: `${palette.primary[500]}55`, // blue faded

    valid: palette.success[500], // green
    invalid: palette.destructive[500], // red
    warning: palette.warning[500],

    // for interactive elements,  maybe move somewhere else
    primaryFocusOutline: palette.primary[500],
    interactiveBackgroundHover: palette.primary[50],
    interactiveBackgroundValid: palette.success[50],
    interactiveBackgroundInvalid: palette.destructive[50],

    text: palette.neutral[900], // asphalt gray
    textSecondary: palette.neutral[600], // lead gray
    textTeritary: palette.neutral[400], // mild gray

    secondary: {
        header: '#fff', // white
        child: palette.success[400], // green
        background: palette.neutral[700], // dark grey
    },

    // light orange/brown hue for visual distinction in lists
    highlightAltBackground: palette.warning[50],
    highlightAltBackgroundHover: palette.warning[100],
};

interface TransactionStates {
    [key: string]: string;
}
const transaction_states: TransactionStates = {
    INITIATED: 'rgba(0, 0, 255, 1.0)',
    ON_HOLD: 'rgba(188, 194, 197, 1.0)',
    DECLINED: '#EB001B',
    FAILED: '#EB001B',
    UNKNOWN: '#EB001B',
    AUTHORIZED: 'rgba(0, 145, 237, 1.0)',
    AUTHORIZATION_VOIDED: 'rgba(188, 194, 197, 1.0)',
    CAPTURED: 'rgba(0, 203, 124, 1.0)',
    PARTIALLY_CAPTURED: 'rgba(0, 203, 124, 1.0)',
    SETTLEMENT_COMPLETED: 'rgba(0, 203, 124, 1.0)',
    NOT_SETTLED: 'rgba(188, 194, 197, 1.0)',
    REFUNDED: 'rgba(157, 83, 157, 1.0)',
    PARTIALLY_REFUNDED: 'rgba(222, 157, 222, 1.0)',
    PARTIALLY_CAPTURED_REFUNDED: 'rgba(222, 157, 222, 1.0)',
};
type TransactionStatus = {
    [key: string]: {
        border: string;
        background: string;
        text: string;
    };
}
const transaction_status: TransactionStatus = {
    INITIATED: { border: '#0000ff', background: '#0000ff', text: '#ffffff' },
    ON_HOLD: { border: '#0000ff', background: '#ffffff', text: '#00006e' },
    DECLINED: { border: '#FFDBDB', background: '#FFDBDB', text: '#00006e' },
    FAILED: { border: '#F51D44', background: '#F51D44', text: '#ffffff' },
    AUTHORIZED: { border: '#0000ff', background: '#0000ff', text: '#ffffff' },
    AUTHORIZATION_VOIDED: { border: '#0000ff', background: '#ffffff', text: '#00006e' },
    CAPTURED: { border: '#48F4C1', background: '#48F4C1', text: '#00006e' },
    PARTIALLY_CAPTURED: { border: '#CEF8F1', background: '#CEF8F1', text: '#00006e' },
    REFUNDED: { border: '#F4EB4A', background: '#F4EB4A', text: '#00006e' },
    PARTIALLY_REFUNDED: { border: '#F3F0BF', background: '#F3F0BF', text: '#00006e' },
    PARTIALLY_CAPTURED_REFUNDED: { border: '#F3F0BF', background: '#F3F0BF', text: '#00006e' },
    UNKNOWN: { border: '#d8d8d8', background: '#ffffff', text: '#00006e' },
    SETTLEMENT_COMPLETED: { border: '#48F4C1', background: '#48F4C1', text: '#00006e' },
    NOT_SETTLED: { border: '#d8d8d8', background: '#ffffff', text: '#00006e' },
};

interface SessionStates {
    [key: string]: string;
}
const session_states: SessionStates = {
    LOADING: 'rgba(0, 145, 237, 1.0)', // focus blue
    INITIATED: 'rgba(0, 145, 237, 1.0)', // focus blue
    VISITED: 'rgba(0, 145, 237, 1.0)', // focus blue
    PRELOADED: 'rgba(0, 145, 237, 1.0)', // focus blue
    PUSH_NOTIFICATION_SENT: 'rgba(0, 145, 237, 1.0)', // focus blue
    COMPLETED: 'rgba(0, 145, 237, 1.0)', // focus blue
    AUTHORIZED: 'rgba(0, 203, 124, 1.0)', // green
    CAPTURED: 'rgba(0, 203, 124, 1.0)', // green
    EXPIRED: '#EFA51C', // yellow
    CANCELLED: '#EFA51C', // yellow
    CANCELED: '#EFA51C', // yellow
    PAY_FAILED: '#EFA51C', // yellow
    FAILED: '#EB001B', // red
    CAPTURE_FAILED: '#EB001B', // red
    UNKNOWN: '#EB001B', // red
    DECLINED: '#EB001B', // red
};

interface RequestStates {
    [key: string]: string;
}
const request_states: RequestStates = {
    FAILED: 'rgba(203, 0, 74, 1.0)',
    OK: 'rgba(0, 203, 124, 1.0)',
};

// the further out in the alphabet, the shorter the distance
const distances = {
    ginormous: '128px',
    large: '64px',
    large40: '40px',
    normal: '32px',
    normal24: '24px',
    normal20: '20px',
    small: '16px',
    small12: '12px',
    tiny: '8px',
    micro: '4px',
    nano: '2px',
};
const gridNumbers = {
    column: 100,
    gutter: 16,
};

const grid = {
    column: `${gridNumbers.column}px`,
    gutter: `${gridNumbers.gutter}px`,
    spans: {
        span1: `${gridNumbers.column}px`,
        span2: `${(gridNumbers.column + gridNumbers.gutter) * 2 - gridNumbers.gutter}px`,
        span3: `${(gridNumbers.column + gridNumbers.gutter) * 3 - gridNumbers.gutter}px`,
        span4: `${(gridNumbers.column + gridNumbers.gutter) * 4 - gridNumbers.gutter}px`,
        span5: `${(gridNumbers.column + gridNumbers.gutter) * 5 - gridNumbers.gutter}px`,
        span6: `${(gridNumbers.column + gridNumbers.gutter) * 6 - gridNumbers.gutter}px`,
        span7: `${(gridNumbers.column + gridNumbers.gutter) * 7 - gridNumbers.gutter}px`,
        span8: `${(gridNumbers.column + gridNumbers.gutter) * 8 - gridNumbers.gutter}px`,
        span9: `${(gridNumbers.column + gridNumbers.gutter) * 9 - gridNumbers.gutter}px`,
        span10: `${(gridNumbers.column + gridNumbers.gutter) * 10 - gridNumbers.gutter}px`,
        span11: `${(gridNumbers.column + gridNumbers.gutter) * 11 - gridNumbers.gutter}px`,
        span12: `${(gridNumbers.column + gridNumbers.gutter) * 12 - gridNumbers.gutter}px`,
    },
};
// Border thickness
const border = {
    normal: '1px',
    double: '2px',
};

const zIndex = {
    behind: -100,
    normal: 0,
    top: 1000,
};

const defaultRadius = '6px';

const globalMobileBreakpoint = 1100;

const globalColumnMaxWidth = 1400;

const globalPageMaxWidth = 2000;

const shadows = {
    xsmall: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    small: '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px -1px rgba(16, 24, 40, 0.10)',
    medium: '0px 4px 6px -1px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.10)',
    large: '0px 10px 15px -3px rgba(16, 24, 40, 0.10), 0px 4px 6px -4px rgba(16, 24, 40, 0.10)',
    xlarge: '0px 20px 25px -5px rgba(16, 24, 40, 0.10), 0px 8px 10px -6px rgba(16, 24, 40, 0.10)',
    xxlarge: '0px 25px 50px -12px rgba(16, 24, 40, 0.25)',
};

export const slideIn = keyframes`
from {
    opacity: 0;
    transform: translateX(2px);
}

to {
    opacity: 1;
    transform: translateX(0);
}
`;

export const fadeIn = keyframes`
 from {
     opacity: 0;
 }
 to {
     opacity: 1;
 }
 `;

export { colors, palette, distances, grid, zIndex, border, transaction_states, transaction_status, session_states, request_states, defaultRadius, globalMobileBreakpoint, globalColumnMaxWidth, globalPageMaxWidth, shadows };
