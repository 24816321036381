import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { border, colors, distances, globalColumnMaxWidth, palette, slideIn } from '../../styles/constants';
import { LoadingOverlay } from '../Loading';

const TableContainer = styled.div.attrs(
    props => ({
        className: ['table-container', props.className].filter(Boolean).join(' '),
    })
)`
    width: 100%;
    padding: 0;
    animation: ${slideIn} 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    overflow-x: auto;
    position: relative;
`;

const TableInner = styled.div.attrs(
    props => ({
        className: ['table', props.className].filter(Boolean).join(' '),
    })
)`
    margin: 0;
    padding: 0;
    width: 100%;
    display: table;

    &.no-wrap > div > a > span,
    &.no-wrap > div > span > span {
        overflow: hidden;
        word-break: normal;
        text-overflow: ellipsis;
    }
`;

type TableProps = {
    children: React.ReactNode;
    className?: string;
    noWrap?: boolean;
    as?: any;
    loading?: boolean;
}


const Table = (props: TableProps) => {
    const { loading, ...rest } = props;
    return (
        <TableContainer>
            <TableInner {...rest} />
            {loading && <LoadingOverlay />}
        </TableContainer>
    );
};

const Thead = styled.div.attrs(props => ({
    className: ['thead', props.className].filter(Boolean).join(' '),
}))`
    display: table-header-group;
    margin: 0;
    padding: 0;
`;

const Tbody = styled.div`
    display: table-row-group;
    margin: 0;
    padding: 0;
`;

const Tfoot = styled.div`
    display: table-footer-group;
    margin: 0;
    padding: 0;
`;

const Tr = styled.span`
    display: table-row;
    margin: 0;
    padding: 0;

    &:last-child > span:after {
        left: 0;
        right: 0;
    }
    &.interactive {
        cursor: pointer;
        &:hover > span {
            &:before {
                content: ' ';
                z-index: -1;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: ${colors.interactiveBackgroundHover};
            }
        }

        .opacity-half-full-on-hover {
            opacity: 0;
        }
        &:hover {
            .opacity-half-full-on-hover {
                opacity: 1;
            }
        }
    }
    & > span.edge {
        max-width: ${distances.normal};
        width: ${distances.normal};
        padding: 0;
        margin: 0;
        &:after {
            display: none;
        }
    }
    &:last-child > span.edge {
        &:after {
            display: block;
        }
    }
`;

const LabelTr = styled.label`
     display: table-row;
     margin: 0;
     padding: 0;
     text-decoration: none;
     cursor: pointer;
     &:last-child > span:after {
         left: 0;
         right: 0;
     }
     &:hover > span {
         &:before {
             content: ' ';
             z-index: -1;
             position: absolute;
             top: 0;
             bottom: 0;
             left: 0;
             right: 0;
             background: ${colors.interactiveBackgroundHover};
         }
     }
     & > span.edge {
         max-width: ${distances.normal};
         width: ${distances.normal};
         padding: 0;
         margin: 0;
         &:after {
             display: none;
         }
     }
     &:last-child > span.edge {
         &:after {
             display: block;
         }
     }
 `;

const LinkTr = styled(Link).attrs(p => ({
    ...p,
    draggable: false,
}))`
    display: table-row;
    margin: 0;
    padding: 0;
    text-decoration: none;
    user-select: text;

    &:last-child > span:after {
        left: 0;
        right: 0;
    }

    &:hover > span {
        &:before {
            content: ' ';
            z-index: -1;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: ${colors.interactiveBackgroundHover};
        }
    }
    .opacity-half-full-on-hover {
        opacity: 0.3;
    }
    &:hover {
        .opacity-half-full-on-hover {
            opacity: 1;
        }
    }
    & > span.edge {
        max-width: ${distances.normal};
        width: ${distances.normal};
        padding: 0;
        margin: 0;
        &:after {
            display: none;
        }
    }
    &:last-child > span.edge {
        &:after {
            display: block;
        }
    }
`;


const ExternalLinkTr = styled.a`
    display: table-row;
    margin: 0;
    padding: 0;
    text-decoration: none;

    &:last-child > span:after {
        left: 0;
        right: 0;
    }

    &:hover > span {
        &:before {
            content: ' ';
            z-index: -1;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: ${colors.interactiveBackgroundHover};
        }
    }
    .opacity-half-full-on-hover {
        opacity: 0.3;
    }
    &:hover {
        .opacity-half-full-on-hover {
            opacity: 1;
        }
    }
    & > span.edge {
        max-width: ${distances.normal};
        width: ${distances.normal};
        padding: 0;
        margin: 0;
        &:after {
            display: none;
        }
    }
    &:last-child > span.edge {
        &:after {
            display: block;
        }
    }
`;


const ClickTr = styled.span`
    display: table-row;
    margin: 0;
    padding: 0;
    text-decoration: none;
    cursor: pointer;

    &:last-child > span:after {
        left: 0;
        right: 0;
    }

    &:hover > span {
        &:before {
            content: ' ';
            z-index: -1;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: ${colors.interactiveBackgroundHover};
        }
    }
    & > span.edge {
        max-width: ${distances.normal};
        width: ${distances.normal};
        padding: 0;
        margin: 0;
        &:after {
            display: none;
        }
    }
    &:last-child > span.edge {
        &:after {
            display: block;
        }
    }
`;

const Th = styled.span.attrs(
    props => ({
        className: ['th', props.className].filter(Boolean).join(' '),
    })
)`
    display: table-cell;
    margin: 0;
    font-size: 12px;
    border-bottom: ${border.normal} solid ${colors.borderLight};
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;


    padding-inline: 16px;
    padding-block: 12px;

    background: ${palette.neutral[900]};
    color: white;

    &.center {
        text-align: center;
    }

    &.first-child {
        padding-left: 16px;
        @media (max-width: 1500px) {
            padding-left: 12px;
        }
    }

    &.last-child {
        padding-right: 16px;
        @media (max-width: 1500px) {
            padding-right: 12px;
        }
    }

    &.align-right {
        text-align: right;
        padding-right: 16px;
        @media (max-width: 1500px) {
            padding-right: 12px;
        }
    }

    &.no-wrap {
        white-space: nowrap;
    }
`;

const TdWrapper = styled.span <{
    borderColor?: string;
}>`
    display: table-cell;
    margin: 0;
    color: ${colors.text};
    font-size: 14px;
    font-weight: 400;
    position: relative;
    z-index: 0;
    font-feature-settings: 'tnum';

    padding-inline: 16px;
    padding-block: 8px;

    &.align-right {
        text-align: right;
        padding-right: 16px;

        @media (max-width: 1500px) {
            padding-right: 12px;
        }
    }

    &:after {
        content: ' ';
        position: absolute;
        height: 1px;
        background: ${props => props.borderColor || colors.borderLight};
        bottom: 0;
        left: 0;
        right: 0;
    }

    &.first-child {
        padding-left: 16px;
        &:after {
            left: 16px;
        }

        @media (max-width: 1500px) {
            padding-left: 12px;
            &:after {
                left: 12px;
            }
        }
    }

    &.last-child {
        padding-right: 16px;
        &:after {
            right: 16px;
        }

        @media (max-width: 1500px) {
            padding-right: 12px;
            &:after {
                right: 12px;
            }
        }
    }
    &.ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        & > * > span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
        }
    }

    &.center {
        text-align: center;
    }

    &.no-ellipsis {
        text-overflow: unset !important;
    }

    &.allow-wrap {
        white-space: normal !important;
        word-wrap: break-word !important;
    }

    @media (max-width: 768px) {
        &.first-child {
            padding-left: ${distances.tiny};
            &:after {
                left: ${distances.tiny};
            }
        }

        &.last-child {
            padding-right: ${distances.tiny};
            &:after {
                right: ${distances.tiny};
            }
        }

        &.align-right {
            text-align: right;
            padding-right: ${distances.tiny};
        }
    }
`;

const TdChild = styled.span<{
    borderColor?: string;
}>`
    min-width: 100px;
    &.no-wrap {
        white-space: nowrap;
    }
    &.ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        & > * > span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
        }
    }

    &.center {
        text-align: center;
    }
    &.no-ellipsis {
        text-overflow: unset !important;
    }

    &.allow-wrap {
        white-space: normal !important;
        word-wrap: break-word !important;
    }

    &:after {
        content: ' ';
        position: absolute;
        height: 1px;
        background: ${props => props.borderColor || colors.borderLight};
        bottom: 0;
        left: 0;
        right: 0;
    }

    &.hidden {
        visibility: hidden;
    }
`;

type TdProps = {
    children?: React.ReactNode;
    className?: string;
    borderColor?: string;
} & React.HTMLAttributes<HTMLSpanElement>;

const Td = ({ children, ...rest }: TdProps) => <TdWrapper {...rest}>
    <TdChild {...rest}>{children}</TdChild>
</TdWrapper>;

export { ClickTr, ExternalLinkTr, LabelTr, LinkTr, Table, Tbody, Td, Th, Thead, Tr, Tfoot };
