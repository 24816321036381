import { useLocation } from 'react-router-dom';
import { Modals as ConnectionModals } from './paymentConnections';

const Modals = () => {
    const location = useLocation();

    const shouldMountConnectionModals = [
        '/settings/payment-connections',
    ].includes(location.pathname);

    return (
        <>
            {shouldMountConnectionModals && <ConnectionModals />}
        </>
    );
};

export default Modals;
